/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useOutletContext, useNavigate } from 'react-router-dom';

import { Divider } from 'primereact/divider';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';

import PageTitle from '../../../../Components/Partials/PageTitle/PageTitle';
import DataTable from '../../../../Components/Datatable/Datatable';
import CTag from '../../../../Components/Partials/CTag/CTag';
import ApplicantDialog from '../../../../Components/Partials/ApplicantDialog/ApplicantDialog';

import moment from 'moment';

import jobPostingService from '../../../../Services/jobPostingServices';
import applicationService from '../../../../Services/applicationServices';

import './ShowJobPosting.css';

export default function ShowJobPostings() {
	const toastRef = useRef(null);
	const [jobPosting, setJobPosting] = useState({ id: 0 });
	const jobPostingId = useParams();
	const [requirement, setRequirement] = useState({ __html: '' });
	const [responsibility, setResponsibility] = useState({ __html: '' });
	const [levelsTemplate, setLevelsTemplate] = useState(<></>);
	const [tagsTemplate, setTagsTemplate] = useState(<></>);
	const [loadingData, setLoadingData] = useState(true);
	const [showApplicantDialog, setShowApplicationDialog] = useState(false);
	const [application, setApplication] = useState({
		id: null,
		applicant: null,
	});

	const context = useOutletContext();

	const navigator = useNavigate();

	const headers = [
		{
			id: 2,
			dataField: 'Applicant.firstName',
			title: 'Name',
		},
		{
			id: 3,
			dataField: 'Applicant.email',
			title: 'Email',
		},
		{
			id: 4,
			dataField: 'Applicant.phoneNumber',
			title: 'Phone Number',
		},
		{
			id: 5,
			dataField: 'createdAt',
			title: 'Date Posted',
		},
		// {
		//     id: 6,
		//     dataField: 'status',
		//     title: 'Application Status',
		// },
	];

	const fetchData = async () => {
		try {
			const jobPosting = await jobPostingService
				.getSingleJobPosting(jobPostingId.id)
				.then((response) => {
					if (response.status === 200) {
						return response.data;
					} else if (response.status === 403) {
						context.logOutUser();
					}
				});
			await setJobPosting(jobPosting);
		} catch (err) {
			console.error(err);
		}
	};

	const fetchApplicant = async (id) => {
		try {
			const application_ = await applicationService
				.fetchApplication(id)
				.then((response) => {
					if (response.status === 200) {
						toastRef.current.show({
							severity: 'success',
							summary: 'Success',
							detail: 'Fetched Job Application',
						});
						return response.data;
					} else if (response.status === 403) {
						context.logOutUser();
					} else {
						toastRef.current.show({
							severity: 'error',
							summary: 'Error',
							detail: 'Could Not Fetch Application Detail',
						});
					}
				});

			setApplication(await application_);
			setShowApplicationDialog(true);
		} catch (e) {
			toastRef.current.show({
				severity: 'error',
				summary: 'Error',
				detail: 'An Error Occured',
			});
		}
	};

	useEffect(() => {
		// TODO: fetch jobposting
		fetchData();
	}, []);

	const publishJobPosting = async () => {
		const response = await jobPostingService
			.publishJobPosting(jobPostingId.id)
			.then((response) => {
				return response.status;
			})
			.catch(() => {
				toastRef.current.show({
					severity: 'error',
					summary: 'Error',
					detail: 'Could Not Updated Job Posting',
				});
			});

		if (response === 200) {
			toastRef.current.show({
				severity: 'success',
				summary: 'Success',
				detail: 'Updated Job Posting',
			});
			await fetchData();
		} else if (response === 403) {
			context.logOutUser();
		} else if (response === 500) {
			toastRef.current.show({
				severity: 'error',
				summary: 'Error',
				detail: 'Could Not Updated Job Posting',
			});
		}
	};

	const publishJobPostingPopup = (event) => {
		confirmPopup({
			target: event.currentTarget,
			message: `Are you sure you want to ${
				jobPosting.isPublished ? 'Unpublish' : 'Publish'
			} this Job Posting`,
			icon: 'pi pi-exclamation-triangle',
			defaultFocus: 'reject',
			accept: publishJobPosting,
			reject: () => {
				return null;
			},
		});
	};

	const deleteJobPosting = async () => {
		const response = await jobPostingService
			.deleteJobPosting(jobPosting.id)
			.then((response) => {
				return response.status;
			})
			.catch(() => {
				toastRef.current.show({
					severity: 'error',
					summary: 'Error',
					detail: 'Could Not Delete Job Posting',
				});
			});

		if (response === 200) {
			navigator('/admin/job-posting');
		} else if (response === 403) {
			context.logOutUser();
		} else if (response === 500) {
			toastRef.current.show({
				severity: 'error',
				summary: 'Error',
				detail: 'Could Not Updated Job Posting',
			});
		}
	};

	const deleteJobPostingPopup = async (event) => {
		confirmPopup({
			target: event.currentTarget,
			message: `Are you sure you want to delete this Job Posting`,
			icon: 'pi pi-exclamation-triangle',
			defaultFocus: 'reject',
			accept: deleteJobPosting,
			reject: () => {
				return null;
			},
		});
	};

	useEffect(() => {
		const lTemplate = () => {
			const levels = jobPosting.Levels?.map((level) => {
				return <CTag key={level.id} id={level.id} />;
			});
			return <div className="mb-2">{levels}</div>;
		};

		const tTemplate = () => {
			const tags = jobPosting.Tags?.map((tag) => {
				return <CTag key={tag.id} id={tag.id}></CTag>;
			});

			return <div className="mb-2">{tags}</div>;
		};
		setRequirement({ __html: jobPosting.requirement });
		setResponsibility({ __html: jobPosting.responsibility });
		setTagsTemplate(tTemplate);
		setLevelsTemplate(lTemplate);
		setLoadingData(false);
	}, [jobPosting]);

	return (
		<>
			<section className="p-3">
				<PageTitle
					title={'Job Posting Details'}
					icon={'pi-file'}
				></PageTitle>
				<Divider></Divider>
				<div className="grid">
					<div
						className="col-8 xl:col-9"
						style={{
							overflowY: 'scroll',
							height: '100%',
							maxHeight: '80vh',
						}}
					>
						{/* Level Section */}
						<div className="mb-3">
							<div
								className={` ${
									!loadingData
										? 'hidden'
										: 'flex align-items-center'
								}`}
							>
								<Skeleton
									className="mr-2"
									width="5rem"
									borderRadius="1rem"
								></Skeleton>
							</div>
							<div
								className={` ${
									loadingData
										? 'hidden'
										: 'flex align-items-center'
								}`}
							>
								{levelsTemplate}
							</div>
						</div>
						{/* Title Section */}
						<div className="mb-3">
							<Skeleton
								className={` ${!loadingData ? 'hidden' : ''}`}
								width="30rem"
								borderRadius="1rem"
								height="1.75rem"
							></Skeleton>
							<h3
								className={` ${
									loadingData ? 'hidden' : ''
								} title`}
							>
								{jobPosting.title}
							</h3>
						</div>
						{/* Tags Section */}
						<div className="mb-3">
							<div
								className={` ${
									!loadingData
										? 'hidden'
										: 'flex align-items-center'
								}`}
							>
								<Skeleton
									className="mr-2"
									width="5rem"
									borderRadius="1rem"
								></Skeleton>
								<Skeleton
									className="mr-2"
									width="5rem"
									borderRadius="1rem"
								></Skeleton>
								<Skeleton
									className="mr-2"
									width="5rem"
									borderRadius="1rem"
								></Skeleton>
							</div>
							<div
								className={` ${
									loadingData
										? 'hidden'
										: 'flex align-items-center'
								}`}
							>
								{tagsTemplate}
							</div>
						</div>
						{/* Uploaded On Section */}
						<div className="mb-4">
							<Skeleton
								width="20rem"
								borderRadius="1rem"
								className={` ${!loadingData ? 'hidden' : ''}`}
							></Skeleton>
							<p
								className={` ${
									loadingData ? 'hidden' : ''
								} title`}
							>
								<span
									className={` ${
										jobPosting.isPublished === false
											? 'hidden'
											: ''
									}`}
								>
									<strong
										style={{
											color: 'var(--green-600)',
											fontWeight: '600',
										}}
									>
										Published At:{' '}
									</strong>
									{moment(jobPosting.createdAt).format(
										'DD MMMM, YYYY'
									)}
								</span>
								<span
									className={`inter ${
										jobPosting.isPublished === true
											? 'hidden'
											: ''
									}`}
									style={{
										fontSize: '0.85rem',
										color: 'var(--surface-400)',
										fontWeight: '500',
									}}
								>
									This Job Posting Has Not Been Published Yet
								</span>
							</p>
						</div>
						<Button
							severity="info"
							className="mb-3 hidden"
							size="small"
							label="Edit Title, Tags, or Levels"
							text
							icon="pi pi-pencil"
							pt={{
								label: {
									style: {
										fontWeight: '500',
									},
								},
							}}
						></Button>
						{/* Description Section */}
						<div className="mb-3">
							<div className="flex align-items-center justify-content-between">
								<h2 className="inter mb-3 section__subtitle">
									Description
								</h2>
								<Button
									severity="info"
									size="small"
									label="Edit Description"
									className="hidden"
									text
									icon="pi pi-pencil"
									pt={{
										label: {
											style: {
												fontWeight: '500',
											},
										},
									}}
								></Button>
							</div>

							<div className={`${!loadingData ? 'hidden' : ''}`}>
								<Skeleton
									width="50rem"
									className="mb-2"
									borderRadius="1rem"
								></Skeleton>
								<Skeleton
									width="50rem"
									className="mb-2"
									borderRadius="1rem"
								></Skeleton>
								<Skeleton
									width="50rem"
									className="mb-2"
									borderRadius="1rem"
								></Skeleton>
								<Skeleton
									width="35rem"
									className="mb-2"
									borderRadius="1rem"
								></Skeleton>
							</div>
							<div className={`${loadingData ? 'hidden' : ''}`}>
								<p>{jobPosting.description}</p>
							</div>
						</div>
						{/* Requirements Section */}
						<div className="mb-3">
							<div className="flex align-items justify-content-between">
								<h2 className="inter mb-3 section__subtitle">
									Requirements
								</h2>
								<Button
									severity="info"
									size="small"
									label="Edit Requirements"
									text
									className="my-3 hidden"
									icon="pi pi-pencil"
									pt={{
										label: {
											style: {
												fontWeight: '500',
											},
										},
									}}
								></Button>
							</div>
							<div className={`${!loadingData ? 'hidden' : ''}`}>
								<Skeleton
									width="50rem"
									className="mb-2"
									borderRadius="1rem"
								></Skeleton>
								<Skeleton
									width="50rem"
									className="mb-2"
									borderRadius="1rem"
								></Skeleton>
								<Skeleton
									width="50rem"
									className="mb-2"
									borderRadius="1rem"
								></Skeleton>
								<Skeleton
									width="35rem"
									className="mb-2"
									borderRadius="1rem"
								></Skeleton>
							</div>
							<div
								className={`${loadingData ? 'hidden' : ''}`}
								dangerouslySetInnerHTML={requirement}
							></div>
						</div>
						{/* Responsibility Section */}
						<div className="mb-3">
							<div className="flex align-items-center justify-content-between">
								<h2 className="inter mb-3 section__subtitle">
									Responsibilities
								</h2>
								<Button
									severity="info"
									size="small"
									label="Edit Responsibility"
									text
									className="my-3 hidden"
									icon="pi pi-pencil"
									pt={{
										label: {
											style: {
												fontWeight: '500',
											},
										},
									}}
								></Button>
							</div>
							<div className={`${!loadingData ? 'hidden' : ''}`}>
								<Skeleton
									width="50rem"
									className="mb-2"
									borderRadius="1rem"
								></Skeleton>
								<Skeleton
									width="50rem"
									className="mb-2"
									borderRadius="1rem"
								></Skeleton>
								<Skeleton
									width="50rem"
									className="mb-2"
									borderRadius="1rem"
								></Skeleton>
								<Skeleton
									width="35rem"
									className="mb-2"
									borderRadius="1rem"
								></Skeleton>
							</div>
							<div
								className={`${loadingData ? 'hidden' : ''}`}
								dangerouslySetInnerHTML={responsibility}
							></div>
						</div>
						{/* Experience Years */}
						<div className="mb-3">
							<div className="flex align-items-center justify-content-between">
								<h2 className="inter mb-3 section__subtitle">
									Experience Years
								</h2>
								<Button
									severity="info"
									size="small"
									label="Edit Experience Years"
									text
									className="hidden"
									icon="pi pi-pencil"
									pt={{
										label: {
											style: {
												fontWeight: '500',
											},
										},
									}}
								></Button>
							</div>
							<div className={`${!loadingData ? 'hidden' : ''}`}>
								<Skeleton
									width="20rem"
									className="mb-2"
									borderRadius="1rem"
								></Skeleton>
							</div>
							<div className={`${loadingData ? 'hidden' : ''}`}>
								<p>{jobPosting.experienceYears}</p>
							</div>
						</div>
						{/* Applicants */}
						<div className="mb-3">
							<h2 className="inter mb-3 section__subtitle">
								Applicants
							</h2>
							<div className={`${!loadingData ? 'hidden' : ''}`}>
								<Skeleton
									width="100%"
									height="10rem"
								></Skeleton>
							</div>
							<div className={`${loadingData ? 'hidden' : ''}`}>
								<div
									className={`${
										!jobPosting.Applications ||
										jobPosting.Applications?.length === 0
											? 'hidden'
											: ''
									}`}
								>
									<DataTable
										items={jobPosting.Applications}
										headers={headers}
										paginator={true}
										rows={10}
										applicantClickEvent={fetchApplicant}
									></DataTable>
								</div>
								<div
									className={`${
										jobPosting.Applications &&
										jobPosting.Applications?.length > 0
											? 'hidden'
											: ''
									}`}
								>
									<Card
										pt={{
											root: {
												style: {
													boxShadow: 'none',
													background:
														'var(--surface-50)',
												},
											},
										}}
									>
										<p className="text-center">
											There are no Applications for this
											Job Posting
										</p>
									</Card>
								</div>
							</div>
						</div>
					</div>
					<div className="col-4 xl:col-3">
						<Panel header="Actions">
							<div>
								<div className="form__container flex flex-column">
									<ConfirmPopup />
									<Button
										size="small"
										label={`${
											jobPosting.isPublished === false
												? 'Publish'
												: 'Unpublish'
										}`}
										severity={`${
											jobPosting.isPublished === false
												? 'success'
												: 'warning'
										}`}
										onClick={publishJobPostingPopup}
									></Button>
									<Button
										size="small"
										label="Delete"
										className="mt-3"
										severity="danger"
										onClick={deleteJobPostingPopup}
									></Button>
								</div>
							</div>
						</Panel>
					</div>
				</div>
			</section>
			<Toast ref={toastRef}></Toast>
			<Dialog
				style={{ width: '50vw' }}
				header="Application Details"
				visible={showApplicantDialog}
				onHide={() => setShowApplicationDialog(false)}
			>
				<ApplicantDialog application={application}></ApplicantDialog>
			</Dialog>
		</>
	);
}
