// * levelService.js
// TODO: POST, GET, DELETE => Levels
import apiManager from './apiManager';

const getLevelIds = async () => {
    try {
        const levels = await apiManager.axios
            .get(`/v1/admin/levels`)
            .then((response) => {
                return { status: response.status, data: response.data.data };
            })
            .catch((err) => {
                return {
                    status: err.response.status,
                    data: null,
                };
            });

        return levels;
    } catch (err) {
        console.error(err);
        return {
            status: null,
            data: null,
        };
    }
};

const levelService = {
    getLevelIds,
};

export default levelService;
