/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Editor } from 'primereact/editor';
import { Message } from 'primereact/message';
import { MultiSelect } from 'primereact/multiselect';
import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

import PageTitle from '../../../../Components/Partials/PageTitle/PageTitle';
import EditorHeader from '../../../../Components/Partials/EditorHeader/EditorHeader';

import './CreateJobPosting.css';

import levelService from '../../../../Services/levelServices';
import tagsService from '../../../../Services/tagServices';
import jobPostingService from '../../../../Services/jobPostingServices';

export default function CreateJobPosting() {
	// ? States
	const [title, setTitle] = useState('');
	const [titleValid, setTitleValid] = useState(true);

	const [description, setDescription] = useState('');
	const [descriptionValid, setDescriptionValid] = useState(true);

	const [requirement, setRequirement] = useState('');
	const [requirementValid, setRequirementValid] = useState(true);

	const [responsibility, setResponsibility] = useState('');
	const [responsibilityValid, setResponsibilityValid] = useState(true);

	const [experienceYears, setExperienceYears] = useState(0);
	const [experienceYearsValid, setExperienceYearsValid] = useState(true);

	const [levelIds, setLevelIds] = useState([]);
	const [levelIdsValid, setLevelIdsValid] = useState(true);

	const [tags, setTags] = useState([]);
	const [tagsValid, setTagsValid] = useState(true);

	const [isPublished, setIsPublished] = useState(false);

	const [uploadingLoader, setUploadingLoader] = useState(false);
	const [updateFormLoader, setUpdateFormLoader] = useState(false);

	const toastRef = useRef(null);

	const [levels, setLevels] = useState([{ id: '' }]);
	const [tagList, setTagList] = useState([{ id: '' }]);

	const publishStatus = [
		{
			id: 1,
			title: 'Publish',
			value: true,
			icon: 'pi pi-globe',
		},
		{
			id: 2,
			title: 'Draft',
			value: false,
			icon: 'pi pi-box',
		},
	];

	const context = useOutletContext();
	const getLevels = async () => {
		const levels_ = await levelService.getLevelIds().then((response) => {
			if (response.status === 403) {
				context.logOutUser();
			}

			return response;
		});
		setLevels(await levels_.data);
	};

	const getTagList = async () => {
		const tagList_ = await tagsService.getTags().then((response) => {
			if (response.status === 403) {
				context.logOutUser();
			}

			return response;
		});
		setTagList(await tagList_.data);
	};

	useEffect(() => {
		getLevels();
		getTagList();
	}, []);
	const isPublishedItemTemplate = (option) => {
		return (
			<div className="flex align-items-center">
				<i className={option.icon}></i>
				<div className="ml-2">{option.title}</div>
			</div>
		);
	};

	const isPublishedSelectedTemplate = (option, props) => {
		if (option) {
			return (
				<div className="flex align-items-center">
					<i className={option.icon}></i>
					<div className="ml-2">{option.title}</div>
				</div>
			);
		}

		return <span>{props.placeholder}</span>;
	};

	const savePanelTemplate = () => {
		return (
			<div className="flex align-items-center">
				<i className="pi pi-save mr-2"></i>
			</div>
		);
	};

	const header = EditorHeader();

	const validateInput = () => {
		setTitleValid(title ? true : false);
		setDescriptionValid(description ? true : false);
		setRequirementValid(requirement ? true : false);
		setResponsibilityValid(responsibility ? true : false);
		setExperienceYearsValid(
			experienceYears !== '' && experienceYears > -1 ? true : false
		);
		setLevelIdsValid(levelIds.length > 0 ? true : false);
		setTagsValid(tags.length > 0 ? true : false);

		return (
			title !== '' &&
			description !== '' &&
			requirement !== '' &&
			responsibility !== '' &&
			experienceYears !== '' &&
			experienceYears > -1 &&
			levelIds.length > 0 &&
			tags.length > 0
		);
	};

	const updateForm = async () => {
		setUpdateFormLoader(true);
		await getLevels()
			.then(() => {
				getTagList();
			})
			.then(() => {
				setUpdateFormLoader(false);
			});
	};

	const uploadJobPosting = async () => {
		setUploadingLoader(true);

		const isValid = validateInput();

		if (!isValid) {
			toastRef.current.show({
				severity: 'error',
				summary: 'Invalid Data',
				detail: 'Please Review the Form Requirements',
			});
			setUploadingLoader(false);
		} else {
			const createObj = {
				title: title,
				description: description,
				requirement: requirement,
				responsibility: responsibility,
				experienceYears: experienceYears,
				levelIds: levelIds,
				tagIds: tags,
				isPublished: isPublished,
			};
			jobPostingService.createJobPosting(createObj).then((response) => {
				if (response.status === 200) {
					setUploadingLoader(false);
					toastRef.current.show({
						severity: 'success',
						summary: 'Success',
						detail: 'Added Job Posting',
					});
					setTitle('');
					setDescription('');
					setRequirement('');
					setResponsibility('');
					setExperienceYears(0);
					setLevelIds([]);
					setTags([]);
					setIsPublished(false);
				} else {
					toastRef.current.show({
						severity: 'error',
						summary: 'Error',
						detail: 'Could Not Add Job Posting',
					});
				}
			});
		}
	};

	return (
		<>
			<section className="p-3">
				<PageTitle
					title={'Create Job Posting'}
					icon={'pi-file-edit'}
				></PageTitle>
				<Divider />
				<div className="grid">
					<div
						className="col-8 xl:col-9"
						style={{
							overflowY: 'scroll',
							height: '100%',
							maxHeight: '80vh',
						}}
					>
						<div className="form__container flex flex-column mb-4">
							<label
								htmlFor="jobPostingTitle"
								className="mb-2 required"
							>
								Title
							</label>
							<InputText
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								id="jobPostingTitle"
								required
								className={`${!titleValid ? 'p-invalid' : ''}`}
								tooltip="Enter the title for Job Posting"
								tooltipOptions={{ position: 'bottom' }}
							></InputText>
							<Message
								severity="error"
								className={`mt-2 ${titleValid ? 'hidden' : ''}`}
								text="This Field is Required"
								style={{ width: 'max-content' }}
							></Message>
						</div>
						<div className="form__container flex flex-column mb-4">
							<label
								htmlFor="jobPostingDescription"
								className="mb-2 required"
							>
								Description
							</label>
							<InputText
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								id="jobPostingDescription"
								className={`${
									!descriptionValid ? 'p-invalid' : ''
								}`}
								required
								tooltip="Enter a short description for the Job Post"
								tooltipOptions={{ position: 'bottom' }}
							></InputText>
							<Message
								severity="error"
								className={`mt-2 ${
									descriptionValid ? 'hidden' : ''
								}`}
								text="This Field is Required"
								style={{ width: 'max-content' }}
							></Message>
						</div>
						<div className="form__container flex flex-column mb-4">
							<label
								htmlFor="jobPostingRequirement"
								className="mb-2 required"
							>
								Requirement
							</label>
							<Editor
								id="jobPostingRequirement"
								value={requirement}
								onTextChange={(e) =>
									setRequirement(e.htmlValue)
								}
								style={{ height: '300px' }}
								required
								headerTemplate={header}
								placeholder="Requirements for the position"
								className={`${
									!requirementValid ? 'p-invalid' : ''
								}`}
								pt={{
									content: {
										style: {
											fontFamily: 'Inter, sans-serif',
										},
									},
								}}
							></Editor>
							<Message
								severity="error"
								className={`mt-2 ${
									requirementValid ? 'hidden' : ''
								}`}
								text="This Field is Required"
								style={{ width: 'max-content' }}
							></Message>
						</div>
						<div className="form__container flex flex-column mb-4">
							<label
								htmlFor="jobPostingResponsibility"
								className="mb-2 required"
							>
								Responsibility
							</label>
							<Editor
								id="jobPostingResponsibility"
								value={responsibility}
								onTextChange={(e) =>
									setResponsibility(e.htmlValue)
								}
								style={{ height: '300px' }}
								headerTemplate={header}
								className={`${
									!responsibilityValid ? 'p-invalid' : ''
								}`}
								pt={{
									content: {
										style: {
											fontFamily: 'Inter, sans-serif',
										},
									},
								}}
								placeholder="Responsibilities for the position"
							></Editor>
							<Message
								severity="error"
								className={`mt-2 ${
									responsibilityValid ? 'hidden' : ''
								}`}
								text="This Field is Required"
								style={{ width: 'max-content' }}
							></Message>
						</div>
						<div className="form__container flex flex-column mb-4">
							<label
								htmlFor="experienceYears"
								className="mb-2 required"
							>
								Experience Years
							</label>
							<InputNumber
								id="experienceYears"
								value={experienceYears}
								onValueChange={(e) =>
									setExperienceYears(e.value)
								}
								min={0}
								max={32}
								showButtons
								buttonLayout="horizontal"
								step={1}
								className={`${
									!experienceYearsValid ? 'p-invalid' : ''
								}`}
								incrementButtonIcon="pi pi-plus"
								decrementButtonIcon="pi pi-minus"
								style={{ maxWidth: '50px' }}
							/>
							<Message
								severity="error"
								className={`mt-2 ${
									experienceYearsValid ? 'hidden' : ''
								}`}
								text="This Field is Required"
								style={{ width: 'max-content' }}
							></Message>
						</div>
						<div className="form__container flex flex-column mb-4">
							<label htmlFor="levelId" className="mb-2 required">
								Levels
							</label>
							<MultiSelect
								id="levelId"
								value={levelIds}
								onChange={(e) => setLevelIds(e.value)}
								options={levels}
								optionLabel="id"
								optionValue="id"
								display="chip"
								placeholder="Select Levels"
								className={`${
									!levelIdsValid ? 'p-invalid' : ''
								}`}
							></MultiSelect>
							<Message
								severity="error"
								className={`mt-2 ${
									levelIdsValid ? 'hidden' : ''
								}`}
								text="This Field is Required"
								style={{ width: 'max-content' }}
							></Message>
						</div>
						<div className="form__container flex flex-column mb-4">
							<label htmlFor="tags" className="mb-2 required">
								Tags
							</label>
							<MultiSelect
								id="tags"
								value={tags}
								onChange={(e) => setTags(e.value)}
								options={tagList}
								optionLabel="id"
								optionValue="id"
								display="chip"
								placeholder="Select Tags"
								className={`${!tagsValid ? 'p-invalid' : ''}`}
							></MultiSelect>
							<Message
								severity="error"
								className={`mt-2 ${tagsValid ? 'hidden' : ''}`}
								text="This Field is Required"
								style={{ width: 'max-content' }}
							></Message>
						</div>
					</div>
					<div className="col-4 xl:col-3">
						<Panel header="Save" icons={savePanelTemplate}>
							<div>
								<div className="form__container flex flex-column mb-4">
									<label
										htmlFor="isPublished"
										className="mb-2"
									>
										Publish This Job Posting
									</label>
									<Dropdown
										size={'small'}
										value={isPublished}
										options={publishStatus}
										optionValue="value"
										optionLabel="title"
										placeholder="Publish this Job Posting"
										onChange={(e) =>
											setIsPublished(e.value)
										}
										itemTemplate={isPublishedItemTemplate}
										valueTemplate={
											isPublishedSelectedTemplate
										}
									></Dropdown>
								</div>
								<Button
									loading={uploadingLoader}
									severity={
										isPublished ? 'success' : 'warning'
									}
									icon={
										isPublished ? 'pi pi-cloud-upload' : ''
									}
									label={
										isPublished
											? 'Publish'
											: 'Save as Draft'
									}
									size="small"
									onClick={uploadJobPosting}
								></Button>
							</div>
						</Panel>
						<Message
							className="mt-3"
							severity="info"
							text="Job Postings saved as draft won't be visible to the public"
						></Message>
						<Panel className="mt-3" header="Misc">
							<div>
								<Button
									loading={updateFormLoader}
									icon="pi pi-refresh"
									size="small"
									onClick={updateForm}
									style={{
										backgroundColor: 'var(--blue-500)',
										borderColor: 'var(--blue-500)',
									}}
									label="Update Form Data"
									tooltip="Update Tags and Levels List"
									tooltipOptions={{ position: 'bottom' }}
								></Button>
							</div>
						</Panel>

						<Toast ref={toastRef}></Toast>
					</div>
				</div>
			</section>
		</>
	);
}
