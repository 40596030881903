// ? Major module imports
import React from 'react';

import { Link } from 'react-router-dom';
// ? UI module imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
// ? Custom module imports
import CTag from '../Partials/CTag/CTag.jsx';
import CPill from '../Partials/CPill/CPill.jsx';

export default function Datatable(props) {
    const tagTemplate = (row) => {
        const row_ = row.Tags.map((tag, index) => {
            return <CTag id={tag.id} key={index}></CTag>;
        });
        return row_;
    };
    const levelTemplate = (row) => {
        const row_ = row.Levels.map((level, index) => {
            return <CTag id={level.id} key={index}></CTag>;
        });
        return row_;
    };
    const isPublishedTemplate = (row) => {
        return <CPill isPublished={row.isPublished}></CPill>;
    };
    const statusTemplate = (row) => {
        const severity =
            row.status === 'pending'
                ? 'warning'
                : row.status === 'approved'
                ? 'info'
                : row.status === 'rejected'
                ? 'danger'
                : row.status === 'success'
                ? 'success'
                : 'primary';
        return (
            <p className="text-center m-0">
                <Tag
                    severity={severity}
                    value={row.status.toUpperCase()}
                    rounded
                ></Tag>
            </p>
        );
    };
    const descriptionTemplate = (row) => {
        return (
            <p
                style={{
                    color: row.description ? '#000000' : 'var(--gray-400)',
                }}
            >
                {row.description || 'N/A'}
            </p>
        );
    };

    const fullNameTemplate = (row) => {
        return (
            <Link
                style={{ color: 'var(--green-500)', textDecoration: 'none' }}
                className="inter"
                onClick={() => props.applicantClickEvent(row.id)}
            >
                {row.Applicant.firstName} {row.Applicant.lastName}
            </Link>
        );
    };

    const titleTemplate = (row) => {
        return (
            <Button
                link
                label={row.title}
                style={{
                    color: 'var(--green-500)',
                }}
                className="inter"
                onClick={() => props.showFunction(row.id)}
            ></Button>
        );
    };

    const columns = props.headers.map((header) => {
        if (header.title === 'Tags') {
            return (
                <Column
                    key={header.id}
                    field={header.dataField}
                    header={header.title}
                    body={tagTemplate}
                    className="inter"
                    headerStyle={{ fontWeight: 500 }}
                ></Column>
            );
        } else if (header.title === 'Levels') {
            return (
                <Column
                    key={header.id}
                    field={header.dataField}
                    header={header.title}
                    body={levelTemplate}
                    className="inter"
                    headerStyle={{ fontWeight: 500 }}
                ></Column>
            );
        } else if (header.title === 'Status') {
            return (
                <Column
                    key={header.id}
                    field={header.dataField}
                    header={header.title}
                    body={isPublishedTemplate}
                    className="inter"
                    headerStyle={{ fontWeight: 500 }}
                ></Column>
            );
        } else if (header.title === 'Description') {
            return (
                <Column
                    key={header.id}
                    field={header.dataField}
                    header={header.title}
                    body={descriptionTemplate}
                    className="inter"
                    headerStyle={{ fontWeight: 500 }}
                ></Column>
            );
        } else if (header.title === 'Title') {
            return (
                <Column
                    key={header.id}
                    field={header.dataField}
                    header={header.title}
                    className="inter"
                    body={titleTemplate}
                    headerStyle={{ fontWeight: 500 }}
                ></Column>
            );
        } else if (header.title === 'Name') {
            return (
                <Column
                    key={header.id}
                    field={header.dataField}
                    header={header.title}
                    className="inter"
                    body={fullNameTemplate}
                    headerStyle={{ fontWeight: 500 }}
                ></Column>
            );
        } else if (header.title === 'Application Status') {
            return (
                <Column
                    key={header.id}
                    field={header.dataField}
                    header={header.title}
                    className="inter"
                    body={statusTemplate}
                    headerStyle={{ fontWeight: 500 }}
                ></Column>
            );
        }
        return (
            <Column
                key={header.id}
                field={header.dataField}
                header={header.title}
                className="inter"
                body=""
                headerStyle={{ fontWeight: 500 }}
            ></Column>
        );
    });

    return (
        <>
            <DataTable
                value={props.items}
                showGridlines
                size="small"
                paginator={props.paginator}
                rows={props.rows}
                rowsPerPageOptions={props.rowsPerPageOptions}
                removableSort
            >
                {columns}
                {/* <Column
                field="createdAt"
                headerStyle={{ fontWeight: 500 }}
                header="Created At"
                sortable
            ></Column> */}
            </DataTable>
        </>
    );
}
