import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Message } from 'primereact/message';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import authService from '../../../Services/authService';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState(false);

    const [emailValid, setEmailValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const toastRef = useRef(null);

    const loginUser = async () => {
        setEmailValid(email !== '' ? true : false);
        setPasswordValid(password !== '' ? true : false);

        if (email !== '' && password !== '') {
            const login = await authService.login({
                email: email,
                password: password,
            });

            if ((await login.status) === 200) {
                navigator('/admin');
            } else {
                setLoginError(true);
                toastRef.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Could not SignIn',
                });
            }
        }
    };

    const navigator = useNavigate();
    const goToPublicRoot = () => {
        navigator('/job-Postings');
    };
    return (
        <section
            style={{
                height: '100vh',
                backgroundColor: 'var(--green-50)',
                position: 'relative',
            }}
            className="flex flex-column align-items-center justify-content-center"
        >
            <Image
                src="/img/logo__full.webp"
                width="275"
                style={{
                    position: 'absolute',
                    top: '1rem',
                    left: '1rem',
                }}
            ></Image>
            <Card
                pt={{
                    root: {
                        style: {
                            boxShadow: 'none',
                        },
                    },
                }}
                title="Sign In"
                subTitle="Please enter your details to access this website"
                style={{ maxWidth: '400px' }}
            >
                <div className="login__form">
                    <Message
                        className={`mb-4 ${!loginError ? 'hidden' : ''}`}
                        text="Email or Password did not match our records"
                        severity="error"
                    ></Message>
                    <div className="form__container flex flex-column mb-4">
                        <label htmlFor="adminEmail" className="mb-2 required">
                            Email
                        </label>
                        <InputText
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={`${!emailValid ? 'p-invalid' : ''}`}
                        ></InputText>
                        <Message
                            severity="error"
                            className={`mt-2 ${emailValid ? 'hidden' : ''}`}
                            text="You need to enter an email"
                            style={{ width: '100%' }}
                        ></Message>
                    </div>
                    <div className="form__container flex flex-column mb-4">
                        <label htmlFor="password" className="mb-2 required">
                            Password
                        </label>
                        <div>
                            <Password
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                toggleMask
                                pt={{
                                    root: {
                                        style: {
                                            width: '100%',
                                        },
                                    },
                                    input: {
                                        style: {
                                            width: '100%',
                                        },
                                    },
                                    panel: {
                                        style: {
                                            display: 'none',
                                        },
                                    },
                                }}
                            ></Password>
                            <Message
                                severity="error"
                                className={`mt-2 ${
                                    passwordValid ? 'hidden' : ''
                                }`}
                                text="You need to enter an email"
                                style={{ width: '100%' }}
                            ></Message>
                        </div>
                    </div>
                    <div className="form__container flex flex-column mb-4">
                        <Button
                            label="Sign In"
                            style={{
                                backgroundColor: 'var(--green-400)',
                                borderColor: 'var(--green-400)',
                            }}
                            onClick={loginUser}
                        ></Button>
                    </div>
                </div>
            </Card>
            <Button
                link
                icon="pi pi-angle-left"
                label="Go Back to Job Postings Page"
                className="mt-4"
                pt={{
                    label: {
                        style: { fontWeight: '500' },
                    },
                }}
                onClick={goToPublicRoot}
            ></Button>
            <Toast ref={toastRef}></Toast>
        </section>
    );
}
