/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Divider } from 'primereact/divider';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';
import { FileUpload } from 'primereact/fileupload';
import { Dialog } from 'primereact/dialog';

import UnAuthHeader from '../../../Components/UnAuthHeader/UnauthHeader';

import jobPostingService from '../../../Services/jobPostingServices';
import applicationService from '../../../Services/applicationServices';

import './Application.css';

export default function Application() {
    // ? Refs
    const fileUploaderRef = useRef(null);
    const toastRef = useRef(null);
    // ? Fetched Data
    const [jobPosting, setJobPosting] = useState({ id: '', title: 'test' });
    // ? Route Query Data
    const jobPostingId = useParams();
    const navigator = useNavigate();

    // ? Form States
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [coverLetter, setCoverLetter] = useState('');
    const [experience, setExperience] = useState([]);
    const [experienceStartDate, setExperienceStartDate] = useState('');
    const [experienceEndDate, setExperienceEndDate] = useState('');
    const [experienceOrganization, setExperienceOrganization] = useState('');
    const [cv, setCv] = useState('');

    // ? Form Validators
    const [firstNameValid, setFirstNameValid] = useState(true);
    const [lastNameValid, setLastNameValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [phoneNumberValid, setPhoneNumberValid] = useState(true);
    const [coverLetterValid, setCoverLetterValid] = useState(true);
    const [experienceStartDateValid, setExperienceStartDateValid] =
        useState(true);
    const [experienceEndDateValid, setExperienceEndDateValid] = useState(true);
    const [experienceOrganizationValid, setExperienceOrganizationValid] =
        useState(true);
    const [experienceTitle, setExperienceTitle] = useState('');
    const [experienceTitleValid, setExperienceTitleValid] = useState(true);
    const [cvValid, setCvValid] = useState(true);

    // ? UI Controller States
    const [cvUploadMessageTrigger, setCvUploadMessageTrigger] = useState(false);
    const [largeFileMessage, setLargeFileMessage] = useState(false);
    const [uploadingDialog, setUploadingDialog] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const fetchJobPosting = useCallback(async () => {
        const jobPostingResponse = await jobPostingService
            .getSingleJobPosting__public(jobPostingId['job-posting-id'])
            .then((response) => {
                setJobPosting(response.data);
            });

        setJobPosting(jobPostingResponse);
    });

    const validateExperienceSubForm = () => {
        setExperienceStartDateValid(experienceStartDate !== '' ? true : false);
        setExperienceEndDateValid(experienceEndDate !== '' ? true : false);
        setExperienceOrganizationValid(experienceEndDate !== '' ? true : false);
        setExperienceTitleValid(experienceTitle !== '' ? true : false);

        return (
            experienceTitle !== '' &&
            experienceStartDate !== '' &&
            experienceEndDate !== '' &&
            experienceOrganization !== ''
        );
    };

    const addExperience = () => {
        const experienceSetIsValid = validateExperienceSubForm();

        const experienceSet = {
            startDate: experienceStartDate,
            endDate: experienceEndDate,
            title: experienceTitle,
            organization: experienceOrganization,
        };
        if (experienceSetIsValid) {
            setExperience([...experience, experienceSet]);
        }
        setExperienceTitle('');
        setExperienceStartDate('');
        setExperienceEndDate('');
        setExperienceOrganization('');
    };

    useEffect(() => {
        fetchJobPosting();
    }, []);

    const deleteItem = (detail) => {
        let tempExperience = [...experience];
        tempExperience = tempExperience.filter(
            (exp) =>
                exp.startDate !== detail.startDate ||
                exp.endDate !== detail.endDate ||
                exp.organization !== detail.organization ||
                exp.title !== detail.title,
        );
        setExperience(tempExperience);
    };

    const carouselTemplate = (detail) => {
        const startDate = `${detail.startDate.getFullYear()}`;
        const endDate = `${detail.endDate.getFullYear()}`;
        const organization = detail.organization;
        const title = detail.title;
        const footerTemplate = () => {
            return (
                <div className="flex align-items-center justify-content-end">
                    <Button
                        severity="danger"
                        label="Remove"
                        size="small"
                        icon="pi pi-trash"
                        text
                        onClick={(e) => {
                            e.preventDefault();
                            deleteItem(detail);
                        }}
                    ></Button>
                </div>
            );
        };
        return (
            <Card
                title="Experience Description"
                style={{
                    backgroundColor: 'var(--primary-50)',
                }}
                pt={{
                    root: {
                        style: { boxShadow: 'none' },
                    },
                    title: {
                        style: {
                            color: 'var(--green-500)',
                            fontSize: '1rem',
                        },
                    },
                    content: {
                        style: {
                            paddingBlock: '0px',
                        },
                    },
                }}
                footer={footerTemplate}
            >
                <p className="mb-0">
                    <strong>Start Date:</strong> {startDate}
                </p>
                <p className="mb-0">
                    <strong>End Date:</strong> {endDate}
                </p>
                <p className="mb-0">
                    <strong>Organization:</strong> {organization}
                </p>
                <p className="mb-0">
                    <strong>Title:</strong> {title}
                </p>
            </Card>
        );
    };

    const uploadCV = (e) => {
        setCv(e.files);
        fileUploaderRef.current.clear();
        setCvUploadMessageTrigger(false);
    };

    const onTemplateSelect = (e) => {
        if (e.files[0].size < 2048000) {
            setCvUploadMessageTrigger(true);
            setLargeFileMessage(false);
        } else {
            fileUploaderRef.current.clear();
            setCvUploadMessageTrigger(false);
            setLargeFileMessage(true);
        }
    };

    const clearUpload = (e) => {
        fileUploaderRef.current.clear();
        setCvUploadMessageTrigger(false);
    };

    const validateForm = () => {
        setFirstNameValid(firstName !== '' ? true : false);
        setLastNameValid(lastName !== '' ? true : false);
        setEmailValid(email !== '' ? true : false);
        setPhoneNumberValid(phoneNumber !== '' ? true : false);
        setCvValid(cv !== '' && cv !== null && cv !== undefined ? true : false);

        return (
            firstName !== '' &&
            lastName !== '' &&
            email !== '' &&
            phoneNumber !== '' &&
            cv !== '' &&
            cv !== null &&
            cv !== undefined
        );
    };

    const uploadApplication = async () => {
        const formValid = validateForm();
        if (formValid) {
            setUploadingDialog(true);
            const createObj = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phoneNumber: phoneNumber,
                experience: JSON.stringify(experience),
                coverLetter: coverLetter,
                jobPostingId: jobPostingId['job-posting-id'],
                cv: cv[0],
            };

            await applicationService
                .createApplication__public(createObj)
                .then((response) => {
                    setUploadingDialog(false);
                    if (response.status === 200) {
                        toastRef.current.show({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Your Application Has Been Uploaded.',
                        });
                        setFormSubmitted(true);
                        setTimeout(() => {
                            navigator('/job-postings');
                        }, 3000);
                    } else {
                        toastRef.current.show({
                            severity: 'warn',
                            summary: 'Warning',
                            detail: 'There was some problem uploading the Application, Please Try Again.',
                        });
                    }
                })
                .catch(() => {
                    setUploadingDialog(false);
                    toastRef.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Could not Submit Application.',
                    });
                });
        }
    };

    const headerTemplate = () => {
        return (
            <div className="flex align-items-center">
                <p>Please Wait</p>
            </div>
        );
    };

    return (
        <>
            <UnAuthHeader />
            <section className="application__section">
                <div className="section__container mt-4 pb-5">
                    <p className="section__title">Apply for This Job Posting</p>
                    <Divider></Divider>
                    <div className="formgrid grid">
                        <div className="field col-12 lg:col-6">
                            <label htmlFor="firsName" className="mb-2 required">
                                First Name
                            </label>
                            <InputText
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                id="firstName"
                                required
                                className={`${
                                    !firstNameValid ? 'p-invalid' : ''
                                } w-full`}
                                tooltip="e.g. John"
                                tooltipOptions={{ position: 'bottom' }}
                            ></InputText>
                            <Message
                                severity="error"
                                className={`mt-2 ${
                                    firstNameValid ? 'hidden' : ''
                                }`}
                                text="First Name is Required"
                                style={{ width: 'max-content' }}
                            ></Message>
                        </div>
                        <div className="field col-12 lg:col-6">
                            <label htmlFor="lastName" className="mb-2 required">
                                Last Name
                            </label>
                            <InputText
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                id="lastName"
                                required
                                className={`${
                                    !lastNameValid ? 'p-invalid' : ''
                                } w-full`}
                                tooltip="e.g. Doe"
                                tooltipOptions={{ position: 'bottom' }}
                            ></InputText>
                            <Message
                                severity="error"
                                className={`mt-2 ${
                                    lastNameValid ? 'hidden' : ''
                                }`}
                                text="Last Name is Required"
                                style={{ width: 'max-content' }}
                            ></Message>
                        </div>
                        <div className="field col-12 lg:col-6">
                            <label htmlFor="email" className="mb-2 required">
                                Email
                            </label>
                            <InputText
                                value={email}
                                type={'email'}
                                onChange={(e) => setEmail(e.target.value)}
                                id="email"
                                required
                                className={`${
                                    !emailValid ? 'p-invalid' : ''
                                } w-full`}
                                tooltip="e.g. john.doe@email.com"
                                tooltipOptions={{ position: 'bottom' }}
                            ></InputText>
                            <Message
                                severity="error"
                                className={`mt-2 ${emailValid ? 'hidden' : ''}`}
                                text="Email is Required"
                                style={{ width: 'max-content' }}
                            ></Message>
                        </div>
                        <div className="field col-12 lg:col-6">
                            <label
                                htmlFor="phoneNumber"
                                className="mb-2 required"
                            >
                                Phone Number
                            </label>
                            <InputText
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                id="phoneNumber"
                                required
                                className={`${
                                    !phoneNumberValid ? 'p-invalid' : ''
                                } w-full`}
                                tooltip="e.g. 9876543210"
                                tooltipOptions={{ position: 'bottom' }}
                            ></InputText>
                            <Message
                                severity="error"
                                className={`mt-2 ${
                                    phoneNumberValid ? 'hidden' : ''
                                }`}
                                text="Phone Number is Required"
                                style={{ width: 'max-content' }}
                            ></Message>
                        </div>
                        <div className="field col-12">
                            <label htmlFor="coverLetter" className="mb-2">
                                Cover Letter
                            </label>
                            <InputTextarea
                                value={coverLetter}
                                onChange={(e) => setCoverLetter(e.target.value)}
                                id="coverLetter"
                                maxLength={500}
                                required
                                className={`${
                                    !coverLetterValid ? 'p-invalid' : ''
                                } mb-0 w-full`}
                                style={{ resize: 'none' }}
                                rows={10}
                                cols={30}
                            ></InputTextarea>
                            <p
                                style={{
                                    color: 'var(--surface-500)',
                                    fontSize: '0.85rem',
                                }}
                                className="mt-0 text-right inter"
                            >
                                {coverLetter.length}/500 words
                            </p>
                        </div>
                        <div className="field col-12">
                            <label className="text-bold text-xl">
                                Experiences{' '}
                                <i
                                    style={{ color: 'var(--green-500)' }}
                                    className="pi pi-info-circle"
                                    id="experienceTitle"
                                    data-pr-tooltip="The sections are required only when you add them."
                                    data-pr-position="center"
                                ></i>
                                <Tooltip target={'#experienceTitle'}></Tooltip>
                            </label>
                        </div>
                        <div className="field col-12">
                            <div className="grid">
                                <div className="col-12 md:col-6 lg:col-4 xl:col-3">
                                    <Card
                                        className={`${
                                            experience.length > 0
                                                ? 'hidden'
                                                : ''
                                        }`}
                                        style={{
                                            backgroundColor:
                                                'var(--surface-50)',
                                        }}
                                        pt={{
                                            root: {
                                                style: { boxShadow: 'none' },
                                            },
                                            title: {
                                                style: {
                                                    fontSize: '1rem',
                                                },
                                            },
                                            content: {
                                                style: {
                                                    paddingBlock: '0px',
                                                },
                                            },
                                        }}
                                    >
                                        <p className="text-center">
                                            Add experience below and they will
                                            appear here
                                        </p>
                                    </Card>
                                    <Carousel
                                        circular
                                        className={`${
                                            experience.length === 0
                                                ? 'hidden'
                                                : ''
                                        } '`}
                                        value={experience}
                                        itemTemplate={carouselTemplate}
                                        numVisible={1}
                                        pt={{
                                            previousButton: {
                                                style: {
                                                    display: 'none',
                                                },
                                            },
                                            nextButton: {
                                                style: {
                                                    display: 'none',
                                                },
                                            },
                                        }}
                                    ></Carousel>
                                </div>
                            </div>
                        </div>
                        <div className="field col-12 lg:col-6">
                            <label
                                htmlFor="experienceStartDate"
                                className="required"
                            >
                                Start Date
                            </label>
                            <Calendar
                                value={experienceStartDate}
                                onChange={(e) =>
                                    setExperienceStartDate(e.value)
                                }
                                dateFormat={'dd/mm/yy'}
                                className={`${
                                    !experienceStartDateValid ? 'p-invalid' : ''
                                } w-full`}
                                tooltip="Since when you began working on that position"
                                tooltipOptions={{ position: 'bottom' }}
                                maxDate={experienceEndDate}
                            ></Calendar>
                            <Message
                                severity="error"
                                className={`mt-2 ${
                                    experienceStartDateValid ? 'hidden' : ''
                                }`}
                                text="Start Date is required"
                                style={{ width: 'max-content' }}
                            ></Message>
                        </div>
                        <div className="field col-12 lg:col-6">
                            <label
                                htmlFor="experienceEndDate"
                                className="required"
                            >
                                End Date
                            </label>
                            <Calendar
                                value={experienceEndDate}
                                minDate={experienceStartDate}
                                onChange={(e) => setExperienceEndDate(e.value)}
                                dateFormat={'dd/mm/yy'}
                                className={`${
                                    !experienceEndDateValid ? 'p-invalid' : ''
                                } w-full`}
                                tooltip="When you stopped working on that position"
                                tooltipOptions={{ position: 'bottom' }}
                            ></Calendar>
                            <Message
                                severity="error"
                                className={`mt-2 ${
                                    experienceEndDateValid ? 'hidden' : ''
                                }`}
                                text="End Date is required"
                                style={{ width: 'max-content' }}
                            ></Message>
                        </div>
                        <div className="field col-12 lg:col-6">
                            <label
                                htmlFor="experienceOrganization"
                                className="required"
                            >
                                Organization
                            </label>
                            <InputText
                                value={experienceOrganization}
                                onChange={(e) =>
                                    setExperienceOrganization(e.target.value)
                                }
                                className={`${
                                    !experienceOrganizationValid
                                        ? 'p-invalid'
                                        : ''
                                } w-full`}
                                tooltip="e.g. Acme Co"
                                tooltipOptions={{ position: 'bottom' }}
                            ></InputText>
                            <Message
                                severity="error"
                                className={`mt-2 ${
                                    experienceOrganizationValid ? 'hidden' : ''
                                }`}
                                text="Organization is required"
                                style={{ width: 'max-content' }}
                            ></Message>
                        </div>
                        <div className="field col-12 lg:col-6">
                            <label
                                htmlFor="experienceTitle"
                                className="required"
                            >
                                Title
                            </label>
                            <InputText
                                value={experienceTitle}
                                onChange={(e) =>
                                    setExperienceTitle(e.target.value)
                                }
                                className={`${
                                    !experienceTitleValid ? 'p-invalid' : ''
                                } w-full`}
                                tooltip="e.g. Clerk"
                                tooltipOptions={{ position: 'bottom' }}
                            ></InputText>
                            <Message
                                severity="error"
                                className={`mt-2 ${
                                    experienceTitleValid ? 'hidden' : ''
                                }`}
                                text="Title is required"
                                style={{ width: 'max-content' }}
                            ></Message>
                        </div>
                        <div className="field col-12">
                            <Button
                                label="Add Experience"
                                onClick={addExperience}
                            ></Button>
                        </div>
                        <div className="field col-12 mt-4">
                            <label htmlFor="cv" className="required">
                                CV
                            </label>
                            <p
                                className={`w-full ${
                                    cv === '' || cv.length === 0 ? 'hidden' : ''
                                }`}
                            >
                                <Tag
                                    rounded
                                    value={
                                        cv !== '' || cv.length > 0
                                            ? 'A file has been uploaded'
                                            : ''
                                    }
                                    style={{
                                        backgroundColor: 'var(--surface-100)',
                                        color: 'var(--surface-500)',
                                        fontWeight: 500,
                                    }}
                                ></Tag>
                            </p>
                            <div className="w-full">
                                <Message
                                    severity="info"
                                    text="Click the button again to Save The Image"
                                    className={`mb-3 ${
                                        !cvUploadMessageTrigger ? 'hidden' : ''
                                    }`}
                                    pt={{
                                        basicButton: {
                                            label: {
                                                style: {
                                                    fontWeight: '500',
                                                },
                                            },
                                        },
                                    }}
                                ></Message>
                                <FileUpload
                                    mode="basic"
                                    accept="application/pdf"
                                    customUpload
                                    onSelect={onTemplateSelect}
                                    uploadHandler={uploadCV}
                                    ref={fileUploaderRef}
                                    className="mb-3"
                                />
                                <Button
                                    severity="danger"
                                    label="Remove File"
                                    icon="pi pi-close"
                                    className={`${
                                        !cvUploadMessageTrigger ? 'hidden' : ''
                                    } mb-3`}
                                    onClick={clearUpload}
                                ></Button>
                                <div className="w-full">
                                    <Message
                                        severity="error"
                                        className={`mt-2 ${
                                            cvValid ? 'hidden' : ''
                                        }`}
                                        text="A CV is Required"
                                        style={{ width: 'max-content' }}
                                    ></Message>
                                    <Message
                                        severity="error"
                                        className={`mt-2 ${
                                            !largeFileMessage ? 'hidden' : ''
                                        }`}
                                        text="File Too Large, Max Size is 2MB"
                                        style={{ width: 'max-content' }}
                                    ></Message>
                                </div>
                            </div>
                        </div>
                        <div className="field col-12 mt-4">
                            <Button
                                label="Submit"
                                disabled={formSubmitted}
                                onClick={uploadApplication}
                            ></Button>
                        </div>
                        <Toast ref={toastRef}></Toast>
                        <Dialog
                            visible={uploadingDialog}
                            onHide={() => setUploadingDialog(false)}
                            header={headerTemplate}
                            pt={{
                                closeButton: {
                                    className: 'hidden',
                                },
                            }}
                        >
                            <p>Your Application is Being Uploaded</p>
                        </Dialog>
                    </div>
                </div>
            </section>
        </>
    );
}
