// * tagService.js
// TODO: POST, GET, DELETE => Tags
import apiManager from './apiManager';

const getTags = async () => {
    try {
        const tags = await apiManager.axios
            .get(`/v1/admin/tags`)
            .then((response) => {
                return { status: response.status, data: response.data.data };
            })
            .catch((err) => {
                console.error(err);
                return {
                    status: err.status,
                    data: null,
                };
            })
            .catch((e) => {
                return {
                    status: e.response.status,
                    data: null,
                };
            });

        return tags;
    } catch (err) {
        console.error(err);
        return {
            status: null,
            data: null,
        };
    }
};

const tagsService = { getTags };

export default tagsService;
