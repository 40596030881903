import React, { useCallback, useEffect, useState } from "react";

import { Divider } from "primereact/divider";
import { Card } from "primereact/card"
import { Link } from "react-router-dom";

import CTag from "../../../Components/Partials/CTag/CTag.jsx"
import UnAuthHeader from "../../../Components/UnAuthHeader/UnauthHeader.jsx";

import jobPostingService from "../../../Services/jobPostingServices";

import "./JobPosting.css"

export default function JobPostings() {

  const [jobPostings, setJobPostings] = useState([])
  const [jobPostTemplate, setJobPostTemplate] = useState(<></>)

  const fetchJobPosting = useCallback(async () => {
    setJobPostings(await jobPostingService.getAllJobPostings__public())
  }, [])

  useEffect(() => {
    fetchJobPosting()
  }, [fetchJobPosting])

  const pageRenderer = useCallback(() => {
    const cardHeader = (obj) => {
      const date = new Date(obj.updatedAt)
      const monthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      return (
        <p className="pt-3 pl-3 font-medium inter" style={{ color: 'var(--surface-500)' }}>
          {`${date.getDate()} ${monthList[date.getMonth()]}, ${date.getFullYear()}`}

        </p>
      )
    }

    const levelsTemplater = (levels) => {
      const levelsElement = levels.map((level) => {
        return <CTag key={level.id} id={level.id} />
      })
      return levelsElement
    }

    const jobPosts = jobPostings.map((jobPost) => {
      const header = cardHeader(jobPost)
      const levelsTemplate = levelsTemplater(jobPost.Levels)
      return (
        <Card key={jobPost.id} pt={
          {
            root: {
              style: {
                boxShadow: 'none',
                backgroundColor: 'var(--green-50)'
              }
            },
            body: {
              style: {
                paddingBlock: '0px'
              }
            },
            content: {
              style: {
                paddingBlock: '0px'
              }
            }
          }} header={header} className="pb-3">
          <div className="job-post__card py-0">
            <div className="mb-2">{levelsTemplate}</div>
            <Link className="job-post__title font-medium" to={`/job-details/${jobPost.id}`}>{jobPost.title}</Link>
            <Divider></Divider>
            <p className="job-post__description">
              {jobPost.description}
            </p>
            <Link className="job-post__view-link" to={`/job-details/${jobPost.id}`}>read more...</Link>
          </div>
        </Card>
      )
    })

    return jobPosts
  }, [jobPostings])

  useEffect(() => {
    const jobPosts = pageRenderer()
    setJobPostTemplate(jobPosts)
  }, [jobPostings, pageRenderer])

  return <>
    <UnAuthHeader />
    <section className="job-posting__section">
      <div className="section__container mt-4 pb-5" >
        <p className="section__title">Available Job Postings</p>
        <Divider />
        {jobPostTemplate}
      </div>
    </section>
  </>;
}
