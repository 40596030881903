// apiService.js
// TODO: Base Axios Settings
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.timeout = 20000;

axios.interceptors.request.use(async (req) => {
	const token = localStorage.getItem('Authorization');

	if (token) {
		req.headers.Authorization = token;
	}

	return req;
});

axios.defaults.headers.post['Content-Type'] = 'application/json';

// eslint-disable-next-line import/no-anonymous-default-export
export default { axios };
