import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import { Menu } from 'primereact/menu';
import './Default.css';
import { Button } from 'primereact/button';

export default function Default() {
	const items = [
		// {
		//     label: 'Dashboard',
		//     icon: 'pi pi-home',
		//     url: '/admin/dashboard',
		// },
		{
			label: 'Job Postings',
			icon: 'pi pi-file',
			items: [
				{
					label: 'List Job Postings',
					icon: 'pi pi-list',
					url: '/admin/job-posting',
				},
				{
					label: 'Create Job Posting',
					icon: 'pi pi-plus',
					url: '/admin/job-posting/create',
				},
			],
		},
	];

	const navigator = useNavigate();

	const logOutUser = () => {
		localStorage.removeItem('Authorization');
		navigator('/admin/login', { replace: true });
	};

	const [user, setUser] = useState(
		localStorage.getItem('Authorization') !== '' &&
			localStorage.getItem('Authorization') !== null &&
			localStorage.getItem('Authorization') !== undefined
	);

	return (
		<main>
			<Header />
			<div
				className="flex default__container"
				style={{ backgroundColor: 'var(--gray-100)' }}
			>
				<div className="col-2 sm:hidden lg:flex sidebar w-100">
					<div
						className="w-full border-round pt-3 flex flex-column "
						style={{
							backgroundColor: 'var(--surface-0)',
							overflow: 'hidden',
						}}
					>
						<Menu
							model={items}
							className="w-full sidebar__menu"
							pt={{ root: { style: { border: 'none' } } }}
						></Menu>
						<Button
							label="Sign Out"
							size="small"
							style={{
								marginTop: 'auto',
								borderRadius: '0px',
								width: '100%',
							}}
							pt={{
								label: {
									style: {
										fontWeight: '500',
									},
								},
							}}
							onClick={logOutUser}
						></Button>
					</div>
				</div>
				<div className="sm:col-12 lg:col-10">
					<div
						style={{ backgroundColor: '#fff' }}
						className="border-round"
					>
						<Outlet context={{ user, setUser, logOutUser }} />
					</div>
				</div>
			</div>
		</main>
	);
}
