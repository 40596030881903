import React from "react";
import { Outlet } from "react-router-dom";

import "./UnAuth.css"

export default function UnAuth() {
  return (
    <>
      <Outlet />
      <footer className="public-footer flex align-items-center justify-content-center"><span>2024 Bhrikuti Stock Broking Company | Developed by <a href="https://beamlab.co">Beam Lab</a></span></footer>
    </>
  );
}
