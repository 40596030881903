import apiManager from "./apiManager";

const login = async (queryObj) => {
  const login = await apiManager.axios.post(`/v1/admin/login`, queryObj).then((response) => {
    return {
      status: response.status,
      data: response.data.user
    }
  }).catch((err) => {
    return {
      status: err.status,
      data: null
    }
  })

  if (await login.status === 200 && await login.data) {
    localStorage.setItem('Authorization', login.data.token)
    localStorage.setItem('userName', login.data.name)
  }
  return login
}

const authService = { login }

export default authService
