// * jobPostingService.js
// TODO: POST, GET, PUT, PATCH, DELETE => JobPostings
import apiManager from './apiManager';

const getAllJobPostings = async () => {
    const jobPostings = await apiManager.axios
        .get(`/v1/admin/job-listings`)
        .then((response) => {
            return { status: response.status, data: response.data.data };
        })
        .catch((e) => {
            return { status: e.response.status, data: null };
        });

    return jobPostings;
};

const getAllJobPostings__public = async () => {
    const jobPostings = await apiManager.axios
        .get(`/v1/public/job-listings`)
        .then((response) => {
            return response.data.data;
        });

    return jobPostings;
};

const getSingleJobPosting__public = async (id) => {
    const jobPosting = await apiManager.axios
        .get(`/v1/public/job-listings/${id}`)
        .then((response) => {
            if (response.status === 200) {
                return { data: response.data.data, status: 200 };
            }
        });

    return jobPosting;
};
const getSingleJobPosting = async (id) => {
    const jobPosting = await apiManager.axios
        .get(`/v1/admin/job-listings/${id}`)
        .then((response) => {
            if (response.status === 200) {
                return { data: response.data.data, status: 200 };
            }
        })
        .catch((e) => {
            return {
                status: e.response.status,
                data: null,
            };
        });

    return jobPosting;
};

const deleteJobPosting = async (id) => {
    const jobPosting = await apiManager.axios
        .delete(`/v1/admin/job-listings/${id}`)
        .then((response) => {
            if (response.status === 200) {
                return { data: response.data.data, status: 200 };
            }
        })
        .catch((e) => {
            return {
                status: e.response.status,
                data: null,
            };
        });

    return jobPosting;
};

const createJobPosting = async (createObj) => {
    const jobPosting = await apiManager.axios
        .post(`/v1/admin/job-listings`, createObj)
        .then((response) => {
            return {
                status: response.status,
                data: response.data.data,
            };
        })
        .catch((err) => {
            return {
                status: err.status,
                data: null,
            };
        });

    return jobPosting;
};

const publishJobPosting = async (id) => {
    const jobPosting = await apiManager.axios
        .patch(`/v1/admin/job-listings/${id}/approve`)
        .then((response) => {
            return {
                status: response.status,
                data: response.data.data,
            };
        })
        .catch((err) => {
            return {
                status: err.response.status,
                data: null,
            };
        });

    return jobPosting;
};

const jobPostingService = {
    getAllJobPostings,
    getAllJobPostings__public,
    getSingleJobPosting__public,
    getSingleJobPosting,
    createJobPosting,
    deleteJobPosting,
    publishJobPosting,
};

export default jobPostingService;
