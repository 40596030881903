import React from 'react';
// ? react router
import { createBrowserRouter } from 'react-router-dom';
// ? layouts
import Default from '../Layouts/Default/Default';
import UnAuth from '../Layouts/UnAuth/UnAuth';

// ? Protected Route Guard
import ProtectedRoute from '../Utils/ProtectedRoute';
// ? pages
// * public
import Application from '../Pages/Public/Application/Application';
import JobDetails from '../Pages/Public/JobDetails/JobDetails';
import JobPostings from '../Pages/Public/JobPostings/JobPostings';

// * admin
// ? Applicants
import ListApplicants from '../Pages/Admin/Applicants/ListApplicants/ListApplicants';
import ShowApplicant from '../Pages/Admin/Applicants/ShowApplicant/ShowApplicant';
// ? Applications
import ListApplications from '../Pages/Admin/Applications/ListApplications/ListApplications';
import ShowApplication from '../Pages/Admin/Applications/ShowApplication/ShowApplication';
// ? Dashboard
import Dashboard from '../Pages/Admin/Dashboard/Dashboard';
// ? JobPostings
import CreateJobPosting from '../Pages/Admin/JobPostings/CreateJobPosting/CreateJobPosting';
import ListJobPostings from '../Pages/Admin/JobPostings/ListJobPostings/ListJobPostings';
import ShowJobPosting from '../Pages/Admin/JobPostings/ShowJobPosting/ShowJobPosting';
import UpdateJobPosting from '../Pages/Admin/JobPostings/UpdateJobPosting/UpdateJobPosting';
// ? Levels
import Levels from '../Pages/Admin/Levels/Levels';
// ? Login
import Login from '../Pages/Admin/Login/Login';
// ? Tags
import Tags from '../Pages/Admin/Tags/Tags';

import ErrorElement from '../Components/ErrorElement/ErrorElement';

export default createBrowserRouter([
	{
		path: '/',
		Component: UnAuth,
		children: [
			{
				path: '/',
				Component: JobPostings,
			},
			{
				path: '/job-postings',
				Component: JobPostings,
			},
			{
				path: '/job-details/:id',
				Component: JobDetails,
			},
			{
				path: '/application/:job-posting-id/apply',
				Component: Application,
			},
		],
	},
	{
		path: '/admin',
		element: <Default />,
		children: [
			{
				element: <ProtectedRoute />,
				children: [
					{
						path: '',
						element: <ListJobPostings />,
					},
					{
						path: 'applicants',
						element: <ListApplicants />,
					},
					{
						path: 'applicants/:id',
						element: <ShowApplicant />,
					},
					{
						path: 'applications',
						element: <ListApplications />,
					},
					{
						path: 'applications/:id',
						element: <ShowApplication />,
					},
					{
						path: 'dashboard',
						element: <Dashboard />,
					},
					{
						path: 'job-posting',
						element: <ListJobPostings />,
					},
					{
						path: 'job-posting/create',
						element: <CreateJobPosting />,
					},
					{
						path: 'job-posting/:id',
						element: <ShowJobPosting />,
					},
					{
						path: 'job-posting/:id/update',
						element: <UpdateJobPosting />,
					},
					{
						path: 'levels',
						element: <Levels />,
					},
					{
						path: 'tags',
						element: <Tags />,
					},
				],
			},
		],
	},
	{
		path: '/admin/login',
		Component: Login,
	},
	{
		path: '*',
		Component: ErrorElement,
	},
]);
