import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CTag from '../../../Components/Partials/CTag/CTag';
import UnAuthHeader from '../../../Components/UnAuthHeader/UnauthHeader';

import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';

import jobPostingService from '../../../Services/jobPostingServices';

import './JobDetails.css';
import { useParams } from 'react-router-dom';

export default function JobDetails() {
    const [jobPosting, setJobPosting] = useState({});
    const [levelsTemplate, setLevelsTemplate] = useState(<></>);
    const [tagsTemplate, setTagsTemplate] = useState(<></>);
    const [publishedDate, setPublishedDate] = useState('');

    const jobPostingId = useParams();

    const fetchJobPosting = useCallback(async () => {
        const response = await jobPostingService.getSingleJobPosting__public(
            jobPostingId.id,
        );
        setJobPosting(await response.data);
    }, [jobPostingId.id]);

    useEffect(() => {
        fetchJobPosting();
    }, [fetchJobPosting]);

    const [requirement, setRequirement] = useState({ __html: '' });
    const [responsibility, setResponsibility] = useState({ __html: '' });

    useEffect(() => {
        const lTemplate = () => {
            const levels = jobPosting.Levels?.map((level) => {
                return <CTag key={level.id} id={level.id} />;
            });
            return <div className="mb-2">{levels}</div>;
        };

        const tTemplate = () => {
            const tags = jobPosting.Tags?.map((tag) => {
                return <CTag key={tag.id} id={tag.id}></CTag>;
            });

            return <div className="mb-2">{tags}</div>;
        };

        setRequirement({ __html: jobPosting.requirement });
        setResponsibility({ __html: jobPosting.responsibility });
        setTagsTemplate(tTemplate);
        setLevelsTemplate(lTemplate);
        const publishedAt = new Date(jobPosting.updatedAt);
        const monthList = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
        setPublishedDate(
            `${publishedAt.getDate()} ${
                monthList[publishedAt.getMonth()]
            }, ${publishedAt.getFullYear()}`,
        );
    }, [jobPosting]);

    const navigator = useNavigate();

    const navToApplication = () => {
        navigator(`/application/${jobPostingId.id}/apply`);
    };

    return (
        <>
            <UnAuthHeader />
            <section className="job-detail__section">
                <div className="section__container mt-4">
                    {levelsTemplate}
                    <h2 className="section__title">{jobPosting.title || ''}</h2>
                    {tagsTemplate}
                    <p>Posted on {publishedDate || ''}</p>
                    <Divider />
                    <h3 className="job-posting__heading">Description</h3>
                    <p className="job-posting__description mb-2">
                        {jobPosting.description}
                    </p>
                    <h3 className="job-posting__heading">Requirements</h3>
                    <div className="job-posting__requirements">
                        <div dangerouslySetInnerHTML={requirement}></div>
                    </div>
                    <h3 className="job-posting__heading">Responsibilities</h3>
                    <div className="job-posting__responsibilities">
                        <div dangerouslySetInnerHTML={responsibility}></div>
                    </div>
                    <Button
                        size="small"
                        pt={{
                            label: {
                                style: { fontWeight: 500 },
                            },
                        }}
                        className="mt-3"
                        label="Apply For This Position"
                        onClick={navToApplication}
                    ></Button>
                </div>
            </section>
        </>
    );
}
