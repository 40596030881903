/* eslint-disable react-hooks/exhaustive-deps */
// ? Major imports
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
// ? UI Modules
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
// ? Components Modules
import Datatable from '../../../../Components/Datatable/Datatable.jsx';
import PageTitle from '../../../../Components/Partials/PageTitle/PageTitle.jsx';
// ? Services
import jobPostingService from '../../../../Services/jobPostingServices';

export default function ListJobPostings() {
	const context = useOutletContext();
	const [jobPostingList, setJobPostingList] = useState([]);

	const [loading, setLoading] = useState(false);

	const fetchStatus = useRef(null);
	const navigate = useNavigate();

	const headers = [
		{
			id: 1,
			dataField: 'id',
			title: 'ID',
		},
		{
			id: 2,
			dataField: 'title',
			title: 'Title',
		},
		{
			id: 3,
			dataField: 'description',
			title: 'Description',
		},
		{
			id: 4,
			dataField: 'experienceYears',
			title: 'Experience',
		},
		{
			id: 5,
			dataField: 'isPublished',
			title: 'Status',
		},
		{
			id: 6,
			dataField: 'Levels',
			title: 'Levels',
		},
		{
			id: 7,
			dataField: 'Tags',
			title: 'Tags',
		},
	];

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const initialJobPosting = jobPostingList
				? jobPostingList.length
				: 0;
			const jobPostings = await jobPostingService
				.getAllJobPostings()
				.then((response) => {
					if (response.status === 200) {
						return response.data;
					} else if (response.status === 403) {
						context.logOutUser();
					}
				});
			await setJobPostingList(jobPostings);
			const fetchedAmount = jobPostings.length - initialJobPosting;
			if (fetchedAmount === 0) {
				await fetchStatus.current.show({
					severity: 'info',
					summary: 'Fetched Nothing',
					detail: `There are no new Job Postings to Fetch`,
				});
			} else {
				await fetchStatus.current.show({
					severity: 'success',
					summary: 'Success',
					detail: `Fetched ${fetchedAmount} Job ${
						fetchedAmount > 1 ? 'Postings' : 'Posting'
					}`,
				});
			}
		} catch (err) {
			console.error(err);
			fetchStatus.current.show({
				severity: 'error',
				summary: 'An Error Occurred',
				detail: 'Could Not Load Job Postings',
			});
		}
	};

	const refreshTable = async () => {
		setLoading(true);
		await fetchData();
		setLoading(false);
	};

	const redirectToAddJobPosting = () => {
		return navigate('/admin/job-posting/create');
	};

	const redirectToShowJobPosting = (id) => {
		return navigate(`/admin/job-posting/${id}`);
	};
	return (
		<>
			<section className="p-3">
				<PageTitle
					title={'All Job Postings'}
					icon={'pi-file'}
				></PageTitle>
				<div className="inter flex align-items-center mb-4">
					<Button
						icon="pi pi-plus"
						onClick={redirectToAddJobPosting}
						label="Add Job Posting"
						size="small"
						className="mr-4"
					></Button>

					<Button
						icon="pi pi-replay"
						label="Refresh"
						outlined
						size="small"
						onClick={refreshTable}
						loading={loading}
					></Button>
				</div>
				<div
					style={{
						overflowY: 'scroll',
						height: '100%',
						maxHeight: '80vh',
					}}
				>
					<Datatable
						items={jobPostingList}
						headers={headers}
						paginator={true}
						rows={10}
						showFunction={redirectToShowJobPosting}
					></Datatable>
				</div>

				<Toast ref={fetchStatus} position="bottom-right"></Toast>
			</section>
		</>
	);
}
