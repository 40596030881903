import { Navigate, Outlet, useOutletContext } from 'react-router-dom';
import React from 'react';

export default function ProtectedRoute() {
    const context = useOutletContext();

    if (context.user === false || context.user === null) {
        return <Navigate to="/admin/login" />;
    }

    return <Outlet context={context}></Outlet>;
}
