import { Tag } from 'primereact/tag';

export default function CPill(prop) {
    return (
        <Tag
            value={prop.isPublished ? 'Published' : 'Draft'}
            className="inter"
            style={{
                backgroundColor: prop.isPublished
                    ? 'var(--green-400)'
                    : 'var(--bluegray-400',
            }}
            rounded={true}
        ></Tag>
    );
}
