import { Card } from 'primereact/card';

import './ApplicantDialog.css';
export default function ApplicantDialog(props) {
    return (
        <>
            <section className="applicantDetails">
                <Card
                    title="Applicant Details"
                    pt={{
                        root: {
                            style: {
                                background: 'var(--surface-50',
                                boxShadow: 'none',
                            },
                        },
                        title: {
                            style: { fontSize: '1.25rem', fontWeight: '500' },
                        },
                    }}
                    className="mb-3"
                >
                    <div className="mt-0 mb-1">
                        <p className="info-header">Full Name: </p>
                        <p>
                            {props.application.Applicant.firstName}{' '}
                            {props.application.Applicant.lastName}
                        </p>
                    </div>
                    <div className="mt-0 mb-1">
                        <p className="info-header">Email: </p>{' '}
                        <p>
                            <a
                                href={`mailto:${props.application.Applicant.email}`}
                                style={{
                                    color: 'var(--green-500)',
                                    textDecoration: 'none',
                                }}
                            >
                                {props.application.Applicant.email}
                            </a>
                        </p>
                    </div>
                    <div className="mt-0 mb-1">
                        <p className="info-header">Phone Number: </p>{' '}
                        <p>
                            <a
                                href={`tel:${props.application.Applicant.phoneNumber}`}
                                style={{
                                    color: 'var(--green-500)',
                                    textDecoration: 'none',
                                }}
                            >
                                {props.application.Applicant.phoneNumber}
                            </a>
                        </p>
                    </div>
                </Card>
                <Card
                    title="Cover Letter and CV"
                    pt={{
                        root: {
                            style: {
                                background: 'var(--surface-50',
                                boxShadow: 'none',
                            },
                        },
                        title: {
                            style: { fontSize: '1.25rem', fontWeight: '500' },
                        },
                    }}
                >
                    <div className="mb-2">
                        <p>{props.application.coverLetter}</p>
                    </div>
                    <div className="mb-1">
                        <p>
                            <p className="info-header mb-2">CV Link: </p>{' '}
                            <a
                                href={props.application.cvLink}
                                style={{
                                    color: 'var(--green-500)',
                                    textDecoration: 'none',
                                }}
                            >
                                Get CV
                            </a>
                        </p>
                    </div>
                </Card>
            </section>
        </>
    );
}
