export default function PageTitle({ title, icon }) {
    return (
        <p className="inter mb-4 page__header">
            <i
                className={`pi ${icon} mr-2`}
                style={{ color: 'var(--red-600)' }}
            ></i>
            {title}
        </p>
    );
}
